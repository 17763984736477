import React from 'react'
import './ContactButton.css'

function ContactButton() {
  return (
    <div className='contact-button neomorphism-dark'>
      <a classnName='button-text' href="mailto:contact@mintnftea.com"> Contact </a>
    </div>
  )
}

export default ContactButton