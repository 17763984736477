import React from 'react'
import { useTranslation } from 'react-i18next'
import ContentCard from '../tiles/ContentCard'
import './ContentSection.css'

function ContentSection(props) {

    const { t } = useTranslation()

    const listElement = []
    // console.info('props',props.list[0].title)
    // console.info('props',props.list[0].cards)

    for (const [i, cardContent] of props.list[0].cards.entries()) {
        // console.log( 'cardContent.title', cardContent.title )
        listElement.push(<ContentCard
            numberLeaf={cardContent.count}
            title={t(props.list[0].title.toLowerCase() + '-title-' + (i+1) )}
            text={t(props.list[0].title.toLowerCase() + '-content-' + (i+1) )}
            key={props.list[0].title.toLowerCase() + '-' + (i+1) }
            />
            )
    }

  return (
      <section>
        <h2 className='roboto-bold-40 sectiontitle'> {props.sectionTitle}</h2>
        <div className='list-container'> 
          {listElement} 
        </div>
      </section>
      
  )

}

export default ContentSection