import React from 'react'
import { Trans, useTranslation } from 'react-i18next';
import ContactButton from './button/ContactButton'
import './Title.css'
import VideoPlayer from './player/VideoPlayer';

function Title() {
    
    const {t} = useTranslation();
  return (
      <>
      <span>
          <div className='only-visible-on-mobile'>
            <VideoPlayer/>
          </div>
        <div>
            <Trans
            i18nKey="title"
             components={{ 1: <h1 className='title' />, span :<span className='title-light' />, 3: <br/> }}> title </Trans>
        </div>
        <div >
            <div className='subtitle-top'>
            <Trans
                i18nKey="subtitle"
                components={{ 1: <div className='subtitle-top 2' />, span :<span /> }}> subtitle </Trans>
            </div>
            
                <ul>
                    <Trans
                        i18nKey="subtitle-history"
                        components={{ 1: <li className='subtitle'/>, span :<span /> }}> subtitle-history </Trans>
                </ul>
                
        </div>
        <div className='title-bottom-line'>
            <div className='subtitle-bottom'>
                <Trans
                    i18nKey="title-under"
                    components={{ 1: <span className='subtitle-bottom emphasize'/>, span :<span /> }}> title-under </Trans>
            </div>
            
            <ContactButton/>
        </div>
        
      </span>
      <span>
          
      </span>
      
      </>
      
  )
}

export default Title