import React from 'react'
import ContentSection from '../section/ContentSection'
import content from '../../content.json'
import Title from '../Title'
import './MainPage.css';

function MainPage() {

  const list = [{ "title": "Expertise", "text": "lorem ipsum" },
  { "title": "Products", "text": "lorem ipsum" }]


  function returnList(title) {
    return content.filter(elem => (elem.title === title))
  }

  return (
    <div className='main-page'>
      <Title />
      <ContentSection sectionTitle='Expertise' list={returnList('Expertise')} />
      {/* <ContentSection sectionTitle='Products' list={returnList('Products')}/> */}
      <ContentSection sectionTitle='Why' list={returnList('Why')} />
    </div>
  )
}

export default MainPage