import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import burgermenu from '../images/burgermenu.png'
import menuCloseIcon from '../images/menuCloseIcon.png'
import ContactButton from '../button/ContactButton'
import './Navbar.css'

function Navbar() {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)

    const linkList = [
        { 'innerSectionLink': true, 'label': 'Expertise' },
        // {'innerSectionLink' : true, 'label':'Products'},
        { 'innerSectionLink': true, 'label': 'Why us' },
        { 'innerSectionLink': false, 'label': 'Founder' }
    ];
    const list = []

    for (const [i, linkVariables] of linkList.entries()) {
        list.push(<span className='nav-links' key={linkVariables.label + '-' + i}> {linkVariables.label} </span>)
    }

    return (
        <nav className="navbar">
            <span className="navbar-container">
                <span className='logo-container'>
                    <Link to="/" className="navbar-logo">
                        <img src={require(`../images/leaf-nft.png`)} className="leaf-icon-title" alt="leaf icon" />
                        <span className='logo-text'>Mint NFTea </span>
                    </Link>
                </span>


                <span className='not-visible-on-mobile nav-links-container'> {list} </span>
                <span className='not-visible-on-mobile'> <ContactButton /> </span>

                <div className='only-visible-on-mobile'>
                    <span className='menu-icon' onClick={handleClick}>
                        <img src={click ? menuCloseIcon : burgermenu} className="icon-menu" alt="open-menu" />
                    </span>
                </div>
            </span>
            {click && <span className='only-visible-on-mobile nav-links-container' > {list} </span>}
        </nav>
    )
}

export default Navbar