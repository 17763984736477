import React from 'react'
import './VideoPlayer.css'

function VideoPlayer() {
  return (
    <div className='video-container neomorphism-dark'>
      <img src={require(`../images/play-icon.png`)} className="play-icon" alt="ether logo" />
      <div className='replay-text'> Video under construction </div>
    </div>
  )
}

export default VideoPlayer