import React from 'react'
import { useTranslation } from 'react-i18next'
import './Footer.css'

function Footer() {
  const { t } = useTranslation()

  return (
    <footer>
      <p>{t('copyrights')}</p>
      {/* <p>{t('donate')}</p> */}
    </footer>
  )
}

export default Footer