import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import MainPage from './components/pages/MainPage';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from './components/context/ThemeContext'
import Footer from './components/footer/Footer';
// import { useTheme, useThemeUpdate } from './components/context/ThemeContext'

function App() {

  // i18n.changeLanguage('fr');
  const { t } = useTranslation()
  
  return (
    <>
    <Router> 
      <ThemeProvider>
        <Navbar/>
        <Routes>
          <Route path='/' element={<MainPage />} />
        </Routes>
        <Footer/>
      </ThemeProvider>
    </Router>
    </>
  );
}

export default App;
