import React from 'react'
import { Trans } from 'react-i18next';
import './ContentCard.css';
// import '../../App.css';


function ContentCard(props) {
  // console.log('this', state)

  function returnImage(){
    
    let name = 'leaf' + props.numberLeaf

    return name
  }

  return (
      <div className='neomorphism-dark dark-theme-font content-card'>
        <div className='not-visible-on-mobile'> 
          <div className='card-header'>
              <img src={ require(`../images/leaf-${props.numberLeaf}.png`) } className="leaf-icon" alt="leaf icon" />
              <div className='tile-title'>
            <Trans
                i18nKey={props.title}
                components={{ 1: <span className='only-visible-on-mobile'/> , 2 : <span className='not-visible-on-mobile' /> }}> {props.title} </Trans>
              </div>
          </div>
          <div>
            <div className='tile-description'> {props.text} </div>
          </div>
        </div>
        <div className='only-visible-on-mobile'>
          <div className='card-header'>
            <img src={ require(`../images/leaf-${props.numberLeaf}.png`) } className="leaf-icon" alt="leaf icon" />
          </div>
          <div>
          <div className='tile-title'>
            <Trans
                    i18nKey={props.title}
                    components={{ 1: <span className='only-visible-on-mobile'/> , 2 : <span className='not-visible-on-mobile' /> }}> {props.title} </Trans>
                    {/* {props.title} */}
                    </div>
            <div className='tile-description'> {props.text} </div>
          </div>
        </div>
      </div>
  )
}

export default ContentCard